import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { loginWithTwitch } from '../InternetAccounts/Utils'
import { login, verify } from '../../Utils/Authentication'
import { GreenButton, GreyButton, DarkButton } from '../SpiceUI/MUI';
import Button from '@mui/material/Button'

import { FaTwitch } from "react-icons/fa";



export function LoginBanner( props )
{
    const navigate = useNavigate()

    return (
        <div style={{
                width: '400px', height: '200px', backgroundColor: 'white',padding:'10px',
                borderRadius: '10px'
        }}>
            <p style={{fontSize:'30px',color:'#5C9286',margin:'0px'}}>Spice Up Your Stream</p>
            <p style={{margin:'0px'}} >Login to access the toolset that </p>
            <p > turns you into a successful streamer.</p>
            <div>
                <GreenButton onClick={e => navigate("/login")} 
                        style={{ width: '115px' }}>
                    Login
                </GreenButton>
            </div>
        </div>
    )
}

export function LoginModule( props )
{
    const destination = props.to

    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState(null)
    const [hasError, setHasError] = useState(false)
    let navigate = useNavigate()

    const loginButton = () => {
        if (username !== null && password !== null) {
            login({ username, password }).then(success => {
                if (success) {
                    setHasError(false)
                    navigate(destination)
                }
                else {
                    setHasError(true)
                }
            })
        }
    }

    return (
        <div className="d-flex justify-content-center align-items-center" 
             style={{ width: '100%', height: '100vh' }}>
            <div style={{ width: '360px', height: '320px',
                          borderRadius: '10px', 
                          padding: '20px'
            }}>
                <div style={{ width:'100%', height:'100px'}}>
                 <img src={'/images/logo-motion-0205_1.gif'} 
                         style={{ width:'auto', height:'80%'}}  
                    />
                </div>

                {/*<p style={{ color: '#5D9386', fontSize: '20px', FontWeight: 400 }}>Spice.Stream</p>*/}
                <div style={{ padding: 30, marginTop:'-30px' }}>
                    <Form.Control className="my-2"
                                  type="text"
                                  name="username"
                                  placeholder="Username"
                                  value={username}
                                  onChange={e => {
                                    //setHasError( false )
                                    setUsername(e.target.value)
                                  }} />
                    <Form.Control className="my-2"
                                  type="password"
                                  name="password"
                                  placeholder="Password"
                                  value={password}
                                  onChange={e => {
                                  //setHasError( false )
                                    setPassword(e.target.value)
                                  }} />
                    <DarkButton variant="contained" 
                                onClick={loginButton} 
                                style={{ width: '100%', boxShadow:'none', marginBottom:'5px',
                                         textTransform:'none'}}>Login</DarkButton>
                    <Button style={{ width:'100%', fontSize:16,color:'#808080', textTransform:'none' }}  
                            onClick={ e => loginWithTwitch("login") }
                    >
                        <FaTwitch style={{ color:'#6441a5', marginRight:'5px', fontSize:20 }}/>Log in with Twitch
                    </Button>
                </div>

            </div>
        </div>
    )
}

export default function Login(props) 
{
    const [ shouldLogin, setShouldLogin ] = useState( false )
    
    if( !shouldLogin )
    {
        return (
            <LoginBanner />
        )
    }


    return (
        <LoginModule />
    )
}




/*export default function Login(props) {
    let navigate = useNavigate();

    const handleLogin=()=>{

        navigate('/login')

    }
    const handleRegister=()=>{

        navigate('/register')

    }
    return (
        <div className="d-flex justify-content-center" style={{ width: '100%', height: '100%', position: 'relative', overflow: 'hidden' }}>
            <div style={{
                width: '400px', height: '200px', backgroundColor: 'white',padding:'10px',
                borderRadius: '10px', position: 'absolute', top: '30px'
            }}>
                <p style={{fontSize:'30px',color:'#5C9286',margin:'0px'}}>Spice Up Your Stream</p>
                <p style={{margin:'0px'}} >Login to access the toolset that </p>
                <p > turns you into a successful streamer.</p>
                <div><Button onClick={handleLogin} className="my-1"  style={{ backgroundColor: '#78A49B', borderColor: '#78A49B', width: '115px',paddingBottom:'3px' }}>Login</Button></div>

            </div>
        </div>
    )
}*/
