import { useEffect, useState, useRef } from "react";
import DNDList from '../Misc/DNDList'

import { IoMdClose } from "react-icons/io";
import { IoMdAddCircle } from "react-icons/io";
import { FaFileUpload } from "react-icons/fa";

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { GiHamburgerMenu } from "react-icons/gi";
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'

import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { DarkButton } from '../SpiceUI/MUI'

import Layers from './Layers'
import Viewer from './Viewer'
import Properties from './Properties'
import MiniMap from "./MiniMap";

import SaveModal from "./SaveModal";
import SaveDiscardModal from "./SaveDiscardModal";

import { binaryStringToArrayBuffer } from '../Misc/FileTools';
import { backendAccessGet, backendAccessPost } from "../../Utils/Authentication";
import Picker from "./Picker";

async function loadImageArray( layers )
{
    const promiseArray = []; // create an array for promises
    const imageArray = {}; // array for the images

    for( var layer of layers )
    {
        if( !layer.patch )
        {
            continue
        }

        promiseArray.push( new Promise( resolve => {
            
            const image = new Image();
            image.onload = function() {
                resolve()
            }
            image.src = 'data:image/png;base64, ' + layer.patch
            imageArray[layer.id] = image
        }))
    }

    await Promise.all(promiseArray);
    return imageArray
}

export function TitledBox( props )
{
    const title = props.title

    return (
        <>
            <div style={{ width:'100%', height:'30px' }}>
                <b>{title}</b>
            </div>
            <div style={{ width:'100%', height:'calc(100% - 30px)' }}>
                { props.children }
            </div>
        </>
    )
}

export function Controls( props )
{
    const showSaveModal = props.showSaveModal
    const createNewWidget = props.createNewWidget
    const hasChange = props.hasChange

    return (
        <div className="flex justify-content-center" style={{ width:'100%',height:'100%', paddingTop:'10px'}}>
            <Button style={{marginBottom:5,width:120, textTransform:'none'}} 
                    onClick={showSaveModal} 
                    variant="contained"
                    color={( hasChange ) ? "error" : "primary"}
                    size="small"
            >
                Save
            </Button>
            <DarkButton onClick={createNewWidget} 
                        style={{width:120, textTransform:'none'}}
                        size="small"
                        variant="contained"
            >
                New Widget
            </DarkButton> 
        </div>
    )
}

export function WidgetBuilder( props )
{
    const setState = props.setState 
    const stateLock = props.stateLock
    const futureState = props.futureState
    const setFutureState = props.setFutureState

    const widgetIdToEdit = props.widgetIdToEdit 
    const setWidgetIdToEdit = props.setWidgetIdToEdit

    const accountType = props.accountType

    const modalOpen = props.modalOpen

    const [ layers, setLayers ] = useState([])
    const [ images, setImages ] = useState({})
    const layersCfg = useRef({})
    const [ selected, setSelected ] = useState("")
    const [ selectedIdx, setSelectedIdx ] = useState(-1)

    const loading = useRef( false )
    const name = useRef("")

    const [ pickerImages, setPickerImages ] = useState([])

    const [ widgetUid, setWidgetUid ] = useState(null)

    const [ showSaveModal, setShowSaveModal ] = useState(false)
    const [ showSaveDiscardModal, setShowSaveDiscardModal ] = useState(false)

    const [ hasChange, setHasChange ] = useState(false)
    const dataJson = useRef(null)
    const backtrack = useRef([])

    const [ reEval, setReEval ] = useState(-1)
    const discardMode = useRef(null)

    useEffect( () => {
        backendAccessGet('/api/foo/').then( res => {
            if( res != null )
            {
                setPickerImages( res.images )
            }
        })

        if( widgetIdToEdit != null )
        {
            fetchServer( widgetIdToEdit )
        }
        else 
        { 
            let widget_data = {}
            widget_data['name'] = "" 
            widget_data['layers'] = []

            let data = {}
            data['widget_data'] = widget_data

            dataJson.current = JSON.stringify( data )
        }
    },[])

    const packWidgetData = () => { 
        let layers_out = []

        for( let layer of layers )
        {
            let o = {}
            o['data'] = layer 
            o['cfg'] = layersCfg.current[layer.id]
            layers_out.push( o )
        }

        var widget_data = {}
        widget_data['name'] = name.current 
        widget_data['layers'] = layers_out

        var data = {}
        data['widget_data'] = widget_data

        return data
    }

    const updateServer = async ( name_, snapshot ) => {
        let data = packWidgetData()
        let data_check = structuredClone(data)

        data.widget_data.name = name_ 
        data.snapshot = snapshot

        if( widgetUid != null )
        {
            data.uid = widgetUid
        }

        let res = await backendAccessPost('/api/widgets/upload/', data)

        if( res == null )
        {
            alert("Failed to upload the file.")
            return false
        }
        
        if( res.error_message )
        {
            alert( res.error_message )
            return false
        }

        if( widgetUid == null || widgetUid != res.widget_uid )
        {
            setWidgetUid( res.widget_uid )
        }

        setHasChange(false)
        dataJson.current = JSON.stringify( data_check )
        setReEval(-1)

        return true
    }

    const setData = ( data ) => {
        
        let ll = []
        let ll_cfg = {}

        for( let layer of data.layers )
        {
            let layer_data = layer.data 
            let layer_cfg = layer.cfg

            if( layer_data.type == null )
            {
                layer_data.type = "image"
            }

            if( layer_cfg.type == null )
            {
                layer_cfg.type = "image"
            }

            ll.push( layer_data )
            ll_cfg[ layer.data.id ] = layer_cfg 
        }

        name.current = data.name
        layersCfg.current = ll_cfg
        setLayers( ll ) 
    }

    const fetchServer = (uid) => {
        let data = {}
        data['widget_uid'] = uid 

        backendAccessPost('/api/widgets/fetch/', data ).then( res => {
            if( res == null )
            {
                return 
            }

            let user_owns = res.user_owns

            let d = {}
            d.widget_data = res.widget_data.data
            dataJson.current = JSON.stringify(d)

            backtrack.current = []
            backtrack.current.push( structuredClone(dataJson.current) )

            if( user_owns )
            {
                setWidgetUid( uid )
            }
            else 
            {
                setWidgetUid(null)
                dataJson.current = '{}'
            }
            setWidgetIdToEdit( null )

            setData( res.widget_data.data )

        })
    }

    useEffect( () => {
        if( dataJson.current == null )
        {
            backtrack.current = []
            return
        }

        var data = packWidgetData()
        var dataJsonLocal = JSON.stringify( data )

        if( dataJsonLocal != dataJson.current )
        {
            stateLock.current = true 
            setHasChange( true )
        }
        else 
        {
            stateLock.current = false
            setHasChange( false )
        }

        if( backtrack.current[backtrack.current.length-1] == dataJsonLocal )
        {
            return;
        }

        backtrack.current.push( dataJsonLocal )

        if( backtrack.current.length > 20 )
        {
            backtrack.current.shift()
        }

    }, [layers, reEval])

    const undo = () => {
        if( backtrack.current.length == 1 )
        {
            return
        }

        backtrack.current.pop()

        let data = JSON.parse( backtrack.current[backtrack.current.length-1] )
        setData( data.widget_data )
    }

    const resetData = () => {
        let widget_data = {}
        widget_data['name'] = ""
        widget_data['layers'] = []

        let data = {}

        data['widget_data'] = widget_data

        dataJson.current = JSON.stringify(data)
        backtrack.current = []
        backtrack.current.push( structuredClone(dataJson.current) )

        setSelected("")
        setSelectedIdx(-1)
        name.current = ""
        setWidgetUid(null)
        setLayers([])
        layersCfg.current = {}
    }

    const closeSDModal = () => {
        setShowSaveDiscardModal(false)        
    }

    const discardCloseSDModal = () => {
        setShowSaveDiscardModal( false )

        if( discardMode.current == "stateChange" )
        {
            stateLock.current = false 
            setState( futureState )

        }
        else if( discardMode.current == "newWidget" ) {
            resetData()
        }
    }

    const saveCloseModal = ( name_, snapshot ) => {
        updateServer( name_, snapshot ).then( res => {
            if( res )
            {
                setShowSaveModal(false)
            }
        }) 
    }

    const saveCloseSDmodal = ( name_, snapshot ) => { 
        updateServer( name_, snapshot ).then( res => {
            if( res )
            {

                if (discardMode.current == "stateChange") 
                {
                    stateLock.current = false 
                    setState( futureState )
                }
                if (discardMode.current == "newWidget") {
                    resetData()
                }

                setShowSaveDiscardModal(false)
            }
        })
    }

    useEffect( () => {
        if( futureState == null ) {
            return 
        }
        discardMode.current = "stateChange"
        setShowSaveDiscardModal( true )
    }, [futureState] )

    const createNewWidget = () => {
        if( hasChange == false ) {
            resetData()
        }
        else 
        {
            discardMode.current = "newWidget"
            setShowSaveDiscardModal( true )
        }
    }

    useEffect( () => {
        loading.current = true
        loadImageArray( layers ).then( imageArray =>
            setImages( imageArray )
        )
    },[ layers ] )

    return (
        <div style={{ width:'100%', height:'100%', padding:'0px'}}>
            <div style={{ width:'150px', height:'100%', float:'left', borderTop:'1px solid #C0C0C0', 
                          padding:'3px', borderTopLeftRadius:'0px', borderBottomLeftRadius:'3px'
                               }}>
                <TitledBox title="Layers">
                    <div style={{ height:'calc(100% - 120px)'}}>
                        <Layers layers={layers} setLayers={setLayers} layersCfg={layersCfg}
                            selected={selected} setSelected={setSelected}
                            selectedIdx={selectedIdx} setSelectedIdx={setSelectedIdx} 
                            pickerImages={pickerImages}
                            accountType={accountType}
                            />
                    </div>
                    <div style={{ height:'100px',marginTop:20}}>
                        <Controls showSaveModal={ e => setShowSaveModal(true)} 
                                  createNewWidget={createNewWidget} 
                                  hasChange={hasChange}
                        />
                    </div>
                </TitledBox>
            </div>
            <div style={{ width:'calc(100% - 450px)', height:'100%', float:'left', 
                          padding:'5px', border:'solid 1px #C0C0C0'}}>
                <Viewer layers={layers} images={images} layersCfg={layersCfg} 
                        selected={selected} setSelected={setSelected}
                        selectedIdx={selectedIdx} setSelectedIdx={setSelectedIdx}
                        reEval={reEval} setReEval={setReEval} loading={loading}
                        modalOpen={modalOpen}
                        undo={undo}
                />
            </div>
            <div style={{ width:'300px', height:'100%', float:'right', 
                          padding:'5px',
                          borderTopRightRadius:'0px', borderBottomRightRadius:'0px', 
                          borderTop:'1px solid #C0C0C0' }}> 
                <TitledBox title="Effects">
                    <Properties layers={layers} 
                                setLayers={setLayers}
                                layersCfg={layersCfg}
                                selected={selected}
                                selectedIdx={selectedIdx}
                                modalOpen={modalOpen}
                                accountType={accountType}
                    />
                </TitledBox>
            </div>
            <SaveModal showModal={showSaveModal}
                closeModal={ e => setShowSaveModal(false)}
                saveCloseModal={saveCloseModal} 
                name={name}
                layers={layers}
                images={images}
                layersCfg={layersCfg}
            />
            <SaveDiscardModal showModal={showSaveDiscardModal}
                closeModal={closeSDModal}
                discardCloseModal={discardCloseSDModal}
                saveCloseModal={saveCloseSDmodal} 
                name={name}
                layers={layers}
                images={images}
                layersCfg={layersCfg}
            />
        </div>
    )
}

export default function WidgetEditor( props )
{
    const setState = props.setState 
    const stateLock = props.stateLock
    const futureState = props.futureState
    const setFutureState = props.setFutureState

    const widgetIdToEdit = props.widgetIdToEdit 
    const setWidgetIdToEdit = props.setWidgetIdToEdit 

    const accountType = props.accountType

    const modalOpen = useRef(false)

    
    return (
        <div style={{ height:'100%', width:'100%' }}>
            <WidgetBuilder setState={setState} 
                           stateLock={stateLock} 
                           futureState={futureState} 
                           setFutureState={setFutureState}
                           widgetIdToEdit={widgetIdToEdit} 
                           setWidgetIdToEdit={setWidgetIdToEdit} 
                           accountType={accountType}
                           modalOpen={modalOpen}
            />
        </div>
    )
}
