import { useState, useEffect } from "react"
import Form from 'react-bootstrap/Form'
import EffectItem from './EffectItem'
import Increment from './Increment'
import ScalarBox from "../../SpiceUI/ScalarBox"
import Button from '@mui/material/Button'

import RegionSelectionModal from './RegionSelectModal'
import { LiaPepperHotSolid } from "react-icons/lia";

export default function TextEffect( props )
{
    const layers = props.layers 
    const setLayers = props.setLayers 
    const layersCfg = props.layersCfg
    const selected = props.selected 
    const selectedIdx = props.selectedIdx
    const modalOpen = props.modalOpen
    const canPlace = props.canPlace
    const disabled = props.disabled

    const [ hasText, setHasText ] = useState(null)

    const [ fontHeight, setFontHeight ] = useState(12)
    const [ mode, setMode ] = useState(null)
    const [ source, setSource ] = useState(null)
    const [ textAlign, setTextAlign ] = useState(null)
    const [ rank, setRank ] = useState(null)
    const [ hashTag, setHashTag ] = useState(null)
    const [ region, setRegion ] = useState(null)

    const [ showModal, setShowModal ] = useState(false)

    const reload = () => {
        if( selectedIdx < 0 )
        {
            return;
        }

        if( !layersCfg.current[selected] )
        {
            return
        }

        if( layersCfg.current[selected].effects.text != null )
        {
            setFontHeight(layersCfg.current[selected].effects.text.font_height)
            setMode(layersCfg.current[selected].effects.text.mode)
            setSource(layersCfg.current[selected].effects.text.source)
            setTextAlign(layersCfg.current[selected].effects.text.text_align)
            setRank(layersCfg.current[selected].effects.text.rank)
            setHashTag(layersCfg.current[selected].effects.text.hash_tag)
            setRegion(layersCfg.current[selected].effects.text.region)
            setHasText(true)
        }
        else
        {
            setHasText(false)
        }
    }

    useEffect( () => {
        reload()
    }, [] )

    useEffect( () => {
        if( hasText == null )
        {
            return
        }

        if( selectedIdx < 0 )
        {
            return
        }

        if( !layersCfg.current[selected] )
        {
            return
        }

        if( !hasText && layersCfg.current[selected].effects.text  )
        {
            delete layersCfg.current[selected].effects.text 
            setLayers( [...layers] )
        }

        if( hasText && !layersCfg.current[selected].effects.text )
        {
            layersCfg.current[selected].effects.text = { font_height:12, mode:'streamer', source:'username', 
                                                         text_align:'left', rank:0, hashTag:'', region:null} 
            setFontHeight(12)
            setMode('streamer')
            setSource('username')
            setTextAlign('left')
            setRank(0)
            setHashTag('')
            setRegion(null)

            setLayers( [...layers] )
        }

    },[ hasText ] )

    useEffect( () => {
        if( !hasText )
        {
            return
        }

        if (selectedIdx < 0 || mode == null || hashTag == null || source == null ) {
            return
        }

        if( !layersCfg.current[selected] )
        {
            return
        }


        var text_data = {
            font_height: fontHeight,
            mode: mode, 
            source: source,
            text_align: textAlign,
            rank: rank,
            hash_tag: hashTag,
            region: region
        }

        layersCfg.current[selected].effects.text = text_data

        setLayers([...layers])

    },[ fontHeight, mode, source, textAlign, rank, hashTag, region ])

    useEffect( () => {
        reload()
    },[selected])

    useEffect( () => {
        modalOpen.current = showModal
    },[showModal])


    return (
        <EffectItem valid={hasText} setValid={setHasText} 
                    name={<>Text</>}
                    disabled={disabled}
        >
            <div className="d-flex align-items-center justify-content-center m-1" >
                <div className=" d-flex align-items-center justify-content-center m-1" 
                     style={{ width: '70px', float: 'left', backgroundColor: '#FFF', 
                              height: '31px',fontSize:'12px' }} >
                    Font height
                </div>
                <Form.Select style={{ width:'calc(100% - 70px)'}} size="sm" 
                             value={fontHeight} 
                             onChange={ e => setFontHeight( parseInt(e.target.value) )}
                >
                    <option value={12}>12</option>
                    <option value={16}>16</option>
                    <option value={18}>18</option>
                    <option value={20}>20</option>
                </Form.Select>
            </div>

            <div className="d-flex align-items-center justify-content-center m-1" >
                <div className=" d-flex align-items-center justify-content-center m-1" 
                     style={{ width: '70px', float: 'left', backgroundColor: '#FFF', 
                              height: '31px',fontSize:'12px' }} >
                    Mode
                </div>
                <Form.Select style={{ width:'calc(100% - 70px)'}} size="sm" 
                             value={mode} 
                             onChange={ e => setMode( e.target.value )}
                >
                    <option value="streamer">Streamer</option>
                    <option value="chatter_1">Big chatter (1 min)</option>
                    <option value="chatter_5">Big chatter (5 min)</option>
                    <option value="hash_tag">Hash tag</option>
                    <option value="last_chat">Last chat</option>
                </Form.Select>
            </div>

            <div className="d-flex align-items-center justify-content-center m-1" >
                <div className=" d-flex align-items-center justify-content-center m-1" 
                     style={{ width: '70px', float: 'left', backgroundColor: '#FFF', 
                              height: '31px',fontSize:'12px' }} >
                    Source
                </div>
                <Form.Select style={{ width:'calc(100% - 70px)'}} size="sm" 
                             value={source} 
                             onChange={ e => setSource( e.target.value )}
                >
                    <option value="username">Username</option>
                    <option value="message">Message</option>
                </Form.Select>
            </div>

            <div className="d-flex align-items-center justify-content-center m-1" >
                <div className=" d-flex align-items-center justify-content-center m-1" 
                     style={{ width: '70px', float: 'left', backgroundColor: '#FFF', 
                              height: '31px',fontSize:'12px' }} >
                    Text align
                </div>
                <Form.Select style={{ width:'calc(100% - 70px)'}} size="sm" 
                             value={textAlign} 
                             onChange={ e => setTextAlign( e.target.value )}
                >
                    <option value="left">Left</option>
                    <option value="center">Center</option>
                    <option value="right">Right</option>
                </Form.Select>
            </div>

            <div className="d-flex align-items-center justify-content-center m-1" >
                <div className=" d-flex align-items-center justify-content-center m-1" 
                     style={{ width: '70px', float: 'left', backgroundColor: '#FFF', 
                              height: '31px',fontSize:'12px' }} >
                    Rank
                </div>
                <Form.Select style={{ width:'calc(100% - 70px)'}} size="sm" 
                             value={rank} 
                             onChange={ e => setRank( parseInt(e.target.value) )}
                >
                    <option value={0}>1</option>
                    <option value={1}>2</option>
                    <option value={2}>3</option>
                    <option value={3}>4</option>
                    <option value={4}>5</option>
                    <option value={5}>6</option>
                    <option value={6}>7</option>
                    <option value={7}>8</option>
                    <option value={8}>9</option>
                    <option value={9}>10</option>
                </Form.Select>
            </div>
            { mode == 'hash_tag' &&
            <div className="d-flex align-items-center justify-content-center m-1" >
                <div className=" d-flex align-items-center justify-content-center m-1" 
                     style={{ width: '50px', float: 'left', backgroundColor: '#FFF', 
                              height: '31px',fontSize:'12px' }} >
                    #
                </div>
                <Form.Control value={hashTag} onChange={e => setHashTag(e.target.value)}
                              style={{ width:'calc(100% - 50px)'}} size="sm" placeHolder="Hash Tag"/>
            </div>
            }

            { canPlace &&
            <div className="d-flex align-items-center justify-content-center m-1" >
                <div className=" d-flex align-items-center justify-content-center m-1" 
                     style={{ width: '100%', backgroundColor: '#FFF', 
                              height: '31px',fontSize:'12px' }} >
                    <Button size="sm" 
                            onClick={ e => setShowModal(true)} 
                            style={{ width:'100%', textTransform:'none' }}
                            variant={( region == null ) ? "contained" : ""}
                            size="small"
                    > 
                        Place text
                    </Button>
                </div>
            </div>
            }

            { layers[selectedIdx] &&
                <RegionSelectionModal showModal={showModal} 
                                      setShowModal={setShowModal} 
                                      label="Text"
                                      image={layers[selectedIdx].patch} 
                                      region={region}
                                      setRegion={setRegion}
                />
            }

        </EffectItem>
    )
}

